@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .social-media {
    @apply text-base md:text-[18px] my-[5px];
  }
}
@font-face {
  font-family: 'GoogleSans';
  src: local('GoogleSans'), url(./assets/fonts/GoogleSans-Regular.ttf),
    format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GoogleSans';
  src: local('GoogleSans'), url(./assets/fonts/GoogleSans-Bold.ttf),
    format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'GoogleSans';
  src: local('GoogleSans'), url(./assets/fonts/GoogleSans-BoldItalic.ttf),
    format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'GoogleSans';
  src: local('GoogleSans'), url(./assets/fonts/GoogleSans-Italic.ttf),
    format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'GoogleSans';
  src: local('GoogleSans'), url(./assets/fonts/GoogleSans-Medium.ttf),
    format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'GoogleSans';
  src: local('GoogleSans'), url(./assets/fonts/GoogleSans-MediumItalic.ttf),
    format('truetype');
  font-weight: 400;
  font-style: italic;
}
body,
html {
  font-family: "Google Sans", sans-serif;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

@media screen and (min-width: 1280px) {
  .no-scrollbar-desktop::-webkit-scrollbar {
    display: none;
  }
  
  .no-scrollbar-desktop {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}

.rounded-full {
  border-radius: 100%;
}

::-webkit-scrollbar-track {
  background-color: #f2f2f2;
}
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #e7e7e7;
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #babac0;
}
.test {
  color: red;
}
.css-yquuwn,
.PrivatePickersToolbar-root.css-ctu4nj {
  background-color: #4285f4;
  color: white;
}
span.MuiTypography-root.MuiTypography-overline.css-1hbyad5-MuiTypography-root,
span.MuiTypography-root.MuiTypography-h3.css-1uwj4ci-MuiTypography-root,
span.MuiTypography-root.MuiTypography-subtitle1.css-1m0r2kt-MuiTypography-root,
span.MuiTypography-root.MuiTypography-h4.Mui-selected.css-wb55pf-MuiTypography-root,
span.MuiTypography-root.MuiTypography-h3.css-1nql4ji-MuiTypography-root,
span.MuiTypography-root.MuiTypography-h3.css-1nql4ji-MuiTypography-root,
span.MuiTypography-root.MuiTypography-h4.css-wb55pf-MuiTypography-root {
  color: white;
}
